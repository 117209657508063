<template>
    <div
        class="content"
        :class="[
            { 'show-overlay': $store.state.app.shallShowOverlay },
            $route.meta.contentClass,
            { 'app-content-operator': operatorMode },
            { 'app-content': !operatorMode },
        ]"
    >
        <div class="content-overlay" />
        <div
            class="content-wrapper"
            :class="contentWidth === 'boxed' ? 'container p-0' : null"
        >
            <div class="content-body">
                <transition :name="routerTransition" mode="out-in">
                    <slot />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
    components: {
        AppBreadcrumb,
    },
    watch: {
        "$route.name"(name) {
            this.operatorMode = name && name.split("-")[0] == "operator";
        },
    },
    mounted() {
        this.operatorMode = this.$route.name?.split("-")[0] == "operator";
    },
    data() {
        return {
            operatorMode: null,
        };
    },
    setup() {
        const { routerTransition, contentWidth } = useAppConfig();

        return {
            routerTransition,
            contentWidth,
        };
    },
};
</script>

<style lang="scss">
.app-content-operator {
    padding: 49px 29px 109px !important;
}

.app-content {
    padding: 20px 29px 0 29px !important;
}

@media (max-width: 768px) {
    .app-content {
        padding: 102px 29px 0 29px !important;
    }

    .app-content-operator {
        padding: 109px 29px !important;
    }
}

@media (max-width: 575px) {
    .app-content {
        padding: 102px 16px 0 16px !important;
    }

    .app-content-operator {
        padding: 109px 16px !important;
    }
}

@media (max-width: 535px) {
    .app-content-operator {
        padding: 108px 16px !important;
    }
}

</style>
