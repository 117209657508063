<template>
    <div v-if="($route.meta.breadcrumb || $route.meta.pageTitle) && $route.name !== 'order-list'" class="content-header">
        <b-col class="content-header-left">
            <div class="breadcrumbs-top">
                <div class="breadcrumb-wrapper-general">
                    <div class="breadcrumb-wrapper row" :style="Object.keys($slots).length > 0 ? '' : 'margin-bottom:0px;'"
                        v-if="!isBreadcrumbWithSelect">
                        <b-breadcrumb class="breadcrumb-chevron">
                            <b-breadcrumb-item v-for="item in breadcrumb" :key="item.text" :active="item.to ? false : true"
                                :to="item.to" :class="[item.to && 'navigable', item.active && 'activePage']">
                                {{
                                    item.active && objectType
                                    ? getObjectType()
                                    : item.active && pipelineName != ''
                                        ? pipelineName
                                        : $t(item.text)
                                }}
                            </b-breadcrumb-item>
                        </b-breadcrumb>
                    </div>



                    <div v-if="isBreadcrumbWithSelect" class="breadcrumb__mobile">
                        <b-breadcrumb-item v-if="hasFirstBreadcrumb" :key="visibleBreadcrumbs.first_breadcrumb.text"
                            :active="visibleBreadcrumbs.first_breadcrumb.to ? false : true"
                            :to="visibleBreadcrumbs.first_breadcrumb.to" :class="[
                                visibleBreadcrumbs.first_breadcrumb.to && 'navigable',
                                visibleBreadcrumbs.first_breadcrumb.active && 'activePage'
                            ]">
                            {{
                                visibleBreadcrumbs.first_breadcrumb.active && objectType
                                ? getObjectType()
                                : visibleBreadcrumbs.first_breadcrumb.active && pipelineName != ''
                                    ? pipelineName
                                    : $t(visibleBreadcrumbs.first_breadcrumb.text)
                            }}
                        </b-breadcrumb-item>
                        <ArrowRightIcon v-if="hasFirstBreadcrumb" />
                        <b-dropdown id="breadcrumb-dropdown">
                            <template #button-content>
                                <div class="dropdown_breadcrumb_button">
                                    <span> ... </span>
                                    <div class="dropdown__division" />
                                    <ArrowRightIcon />
                                </div>
                            </template>
                            <b-dropdown-item v-for="(item, index) in dropdownList" :key="index" :to="item.to">
                                {{
                                    item.active && objectType
                                    ? getObjectType()
                                    : item.active && pipelineName != ''
                                        ? pipelineName
                                        : $t(item.text)
                                }}
                            </b-dropdown-item>
                        </b-dropdown>

                        <ArrowRightIcon v-if="hasLastBreadcrumb" />

                        <b-breadcrumb-item v-if="hasLastBreadcrumb" :key="visibleBreadcrumbs.last_breadcrumb.text"
                            :active="visibleBreadcrumbs.last_breadcrumb.to ? false : true"
                            :to="visibleBreadcrumbs.last_breadcrumb.to" class="last-breadcrumb-resume">
                            {{
                                visibleBreadcrumbs.last_breadcrumb.active && objectType
                                ? getObjectType()
                                : visibleBreadcrumbs.last_breadcrumb.active && pipelineName != ''
                                    ? pipelineName
                                    : $t(visibleBreadcrumbs.last_breadcrumb.text)
                            }}
                        </b-breadcrumb-item>
                    </div>
                </div>

                <div class="slot-breadcrumb" v-if="Object.keys($slots).length > 0 ? true : false">
                    <slot name="col-right" />
                </div>
            </div>
        </b-col>
    </div>
</template>

<script>
import {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import IconHome from '../../assets/images/pages/home-page-icon.svg';
import IconIntegration from '../../assets/images/pages/integration-page-icon.svg';
import ArrowRightIcon from '@core/assets/icons/arrow-right.svg';
import IconSettings from '../../assets/images/pages/settings-page-icon.svg';
import IconOperationProgress from '../../assets/images/pages/operation-progress-page-icon.svg';
import IconOrder from '../../assets/images/pages/order-page-icon.svg';
import IconDashboard from '../../assets/images/pages/dashboard-page-icon.svg';
import IconProfile from '../../assets/images/pages/profile-icon.svg';
import IconOperator from '@/assets/images/pages/operator-mode-icon.svg';
import router from '@/router';

export default {
    directives: {
        Ripple
    },
    components: {
        BBreadcrumb,
        BBreadcrumbItem,
        BRow,
        BCol,
        BDropdown,
        BDropdownItem,
        BButton,
        ArrowRightIcon,
        IconHome,
        IconIntegration,
        IconSettings,
        IconOperationProgress,
        IconOrder,
        IconDashboard,
        IconProfile,
        IconOperator
    },
    props: {
        objectType: {
            type: String
        },
        pipelineName: {
            type: String,
            default: ''
        },
        device: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            breadcrumb: {},
            breadcrumbHidden: false,
            isBreadcrumbWithSelect: false,
            hasFirstBreadcrumb: true,
            hasLastBreadcrumb: true,
            firstBreadWasRemoved: false,
            passedTheLastValidation: false,
            passedTheFirstValidation: false,
            dropdownList: []
        };
    },
    mounted() {
        this.breadcrumb = this.$route.meta.breadcrumb;

        router.afterEach(() => {
            setTimeout(() => {
                this.isBreadcrumbWithSelect = false;
                this.hasFirstBreadcrumb = true;
                this.hasLastBreadcrumb = true;
                this.dropdownList = [];
            }, 500);
        });

        window.addEventListener('resize', this.handleResizeListener);
    },
    updated() {
        this.breadcrumb = this.$route.meta.breadcrumb;

        this.handleBreadcrumb();
    },
    methods: {
        handleResizeListener() {
            this.isBreadcrumbWithSelect = false;
            this.hasFirstBreadcrumb = true;
            this.hasLastBreadcrumb = true;
            this.dropdownList = [];

            this.$nextTick(() => {
                setTimeout(() => {
                    this.handleBreadcrumb();
                }, 1000);
            });
        },
        handleBreadcrumb() {
            const sumPaddings = 32;
            const widthNavbarMobile = document.querySelector('#navbar-breadcrumbs')?.clientWidth;
            const widthNavbarMobileCurrent =
                document.querySelector('.breadcrumb-wrapper-general')?.clientWidth + sumPaddings;

            if (widthNavbarMobileCurrent > widthNavbarMobile && this.device !== '')
                return this.handleResponsiveness();

            const widthNavbar = document.querySelector('#navbar')?.clientWidth;
            const widthNavbarCurrent =
                document.querySelector('.side')?.clientWidth +
                document.querySelector('.profile')?.clientWidth;
            if (widthNavbarCurrent > widthNavbar && this.device === '')
                return this.handleResponsiveness();
        },
        handleResponsiveness() {
            if (this.breadcrumb.length >= 1) {
                this.isBreadcrumbWithSelect = true;
            }

            if (this.breadcrumb.length - this.dropdownList.length === 1) {
                this.hasFirstBreadcrumb = false;
                this.hasLastBreadcrumb = false;
                this.dropdownList = [...this.breadcrumb];
            }

            if (this.breadcrumb.length - this.dropdownList.length === 2) {
                this.hasFirstBreadcrumb = false;
                this.dropdownList = [this.breadcrumb[0], ...this.dropdownList];
            }

            if (this.breadcrumb.length - this.dropdownList.length >= 3) {
                this.dropdownList = this.breadcrumb.filter(
                    (_, index) => index !== 0 && index !== this.breadcrumb.length - 1
                );
            }
        },
        getObjectType() {
            switch (this.objectType) {
                case '2':
                    return 'SQL Server';
                case '3':
                    return 'SAP';
                case '4':
                    return 'CSV';
                case '5':
                    return 'REST';
                case '6':
                    return 'Data Factory';
                default:
                    return 'TROIKA SFC';
            }
        },
        hiddenBreadcrumbs() {
            return this.breadcrumb.slice(1, -1);
        }
    },
    computed: {
        visibleBreadcrumbs() {
            const fisrtNLastBreadcrumb = {
                first_breadcrumb: this.breadcrumb[0],
                last_breadcrumb: this.breadcrumb[this.breadcrumb.length - 1]
            };
            return fisrtNLastBreadcrumb;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResizeListener);
    }
};
</script>
<style lang="scss">
.breadcrumb {
    flex-wrap: nowrap;
}
</style>

<style lang="scss" scoped>
.breadcrumb__mobile {
    display: flex;
    align-items: center;
    gap: 12px;

    li {
        list-style-type: none;
    }

    svg {
        fill: #998f8a;
        width: 16px;
        height: 16px;
    }

    .last-breadcrumb-resume {
        color: #4C4541;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }
}

.breadcrumb-dropdown-toggle {
    cursor: pointer;
    text-decoration: none;
}
</style>
<style lang="scss">
@media (max-width: 768px) {
    .breadcrumbs-top {
        flex-direction: column;

        .breadcrumb-wrapper {
            margin-bottom: 24px;
        }
    }

    .breadcrumb__mobile {
        padding: 8px 0;
    }
}

#breadcrumb-dropdown {
    display: flex;
    gap: 8px;
    height: 20px;

    span {
        padding: 0 0 0 8px;
    }

    svg {
        width: 10px;
        height: 10px;
        margin-right: 4px;
        transform: rotate(90deg);
    }

    .dropdown_breadcrumb_button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 43px;
    }

    .dropdown__division {
        height: 20px;
        width: 1px;
        background: #eee;
        margin: 0 4px 0 8px;
    }

    button {
        background-color: #fff !important;
        border-radius: 8px !important;
        border: 1px solid #eee !important;
        color: #998f8a !important;
        padding: 0 !important;
        max-height: 20px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;

        display: flex;
        align-items: center;
    }

    .dropdown-toggle::after {
        display: none !important;
    }
}

.breadcrumbs-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .breadcrumb__mobile {
        span {
            white-space: nowrap;
        }
    }

    .breadcrumb-wrapper {
        padding: 4px 0;

        .breadcrumb.breadcrumb-chevron {
            padding-inline: 16px !important;
        }

        .breadcrumb-item {
            display: flex;
            align-items: center;
            padding: 0 12px 0 0;
            cursor: pointer;

            &:hover {
                &.navigable {
                    a {
                        color: #FFB683 !important;
                        text-decoration: underline;
                    }

                    span {
                        color: #FFB683 !important;
                    }
                }
            }

            &::before {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                margin-right: 12px !important;
                width: 16px;
                height: 16px;
                padding: 0;
            }

            span {
                display: flex;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #998f8a;
                white-space: nowrap;
            }

            &.activePage {
                padding-left: 0 !important;

                span {
                    color: #4c4541;
                    font-weight: 600;
                }
            }

            &.navigable {
                a {
                    color: #998f8a;
                }
            }
        }
    }

    &.slot-breadcrumb {
        flex: 1;
    }
}
</style>
