var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"sidebar",class:[
            _vm.isOpen ? 'open' : '',
            _vm.wide ? 'wide' : '',
            _vm.device !== 'desktop' ? 'full-wide' : '' ],attrs:{"id":"menu-bar"}},[_c('UnitSelector',{directives:[{name:"show",rawName:"v-show",value:(_vm.device === 'mobile'),expression:"device === 'mobile'"}],attrs:{"variant":_vm.variant,"device":_vm.device,"close-dropdown":_vm.closeDropdown}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.isOpen
                    ? true
                    : !_vm.overflowNavItems.find(
                          function (item) { return item.id == 'operator'; }
                      )
            ),expression:"\n                isOpen\n                    ? true\n                    : !overflowNavItems.find(\n                          (item) => item.id == 'operator'\n                      )\n            "}],attrs:{"name":_vm.operatorMode ? _vm.$t('PCP Mode') : _vm.$t('Operator Mode'),"variant":_vm.variant,"icon":_vm.operatorMode ? _vm.icons.PCPMode : _vm.icons.Operator,"data-redirect":"operator"},on:{"click":function () { return _vm.toggleOperator(); }}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.isOpen
                    ? true
                    : !_vm.overflowNavItems.find(function (item) { return ['operator-orders-all', 'order-list'].includes(
                              item.id
                          ); }
                      )
            ),expression:"\n                isOpen\n                    ? true\n                    : !overflowNavItems.find((item) =>\n                          ['operator-orders-all', 'order-list'].includes(\n                              item.id\n                          )\n                      )\n            "}],attrs:{"name":_vm.$t('Orders'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/order'),"icon":_vm.icons.TotalOrders,"data-redirect":_vm.operatorMode ? 'operator-orders-all' : 'order-list'},on:{"click":function () { return _vm.redirect(
                        _vm.operatorMode ? 'operator-orders-all' : 'order-list'
                    ); }}}),(_vm.operatorMode)?_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.isOpen
                    ? true
                    : !_vm.overflowNavItems.find(
                          function (item) { return item.id == 'operator-barcode'; }
                      )
            ),expression:"\n                isOpen\n                    ? true\n                    : !overflowNavItems.find(\n                          (item) => item.id == 'operator-barcode'\n                      )\n            "}],attrs:{"name":_vm.$t('Operator.BarCodeTitle'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/barcode'),"icon":_vm.icons.BarcodeIcon,"data-redirect":"operator-barcode"},on:{"click":function () { return _vm.redirect('operator-barcode'); }}}):_vm._e(),(!_vm.operatorMode)?_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.isOpen
                    ? true
                    : !_vm.overflowNavItems.find(
                          function (item) { return item.id == 'production-progress'; }
                      )
            ),expression:"\n                isOpen\n                    ? true\n                    : !overflowNavItems.find(\n                          (item) => item.id == 'production-progress'\n                      )\n            "}],attrs:{"name":_vm.$t('Menu.Items.Appointments'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/production-progress'),"icon":_vm.icons.OperationProgress,"data-redirect":"production-progress"},on:{"click":function () { return _vm.redirect('production-progress'); }}}):_vm._e(),(!_vm.operatorMode)?_c('CollapseNavItem',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.isOpen
                    ? true
                    : !_vm.overflowNavItems.find(
                          function (item) { return item.id == 'dashboards'; }
                      )
            ),expression:"\n                isOpen\n                    ? true\n                    : !overflowNavItems.find(\n                          (item) => item.id == 'dashboards'\n                      )\n            "}],attrs:{"name":_vm.$t('Dashboards'),"items":_vm.dashboardItems,"variant":_vm.variant,"icon":_vm.icons.DashboardIcon,"close-dropdown":_vm.closeDropdown,"data-redirect":"dashboards"}}):_vm._e(),(!_vm.operatorMode)?_c('CollapseNavItem',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.isOpen
                    ? true
                    : !_vm.overflowNavItems.find(
                          function (item) { return item.id == 'settings'; }
                      )
            ),expression:"\n                isOpen\n                    ? true\n                    : !overflowNavItems.find(\n                          (item) => item.id == 'settings'\n                      )\n            "}],attrs:{"name":_vm.$t('Settings'),"items":_vm.settingsItems,"variant":_vm.variant,"icon":_vm.icons.SettingsIcon,"close-dropdown":_vm.closeDropdown,"data-redirect":"settings"}}):_vm._e(),_c('Extra',{directives:[{name:"show",rawName:"v-show",value:(_vm.overflowNavItems.length),expression:"overflowNavItems.length"}],attrs:{"items":_vm.overflowNavItems,"variant":_vm.variant}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }