<template>
  <div v-if="!operatorMode">
    <Navbar
      :user="user"
      :device="device"
      @logout="logout"
      :operatorMode.sync="operatorMode"
      @toggleOperator="toggleOperator"
    />
    <Sidebar
      v-if="device == 'desktop'"
      :device="device"
      :operatorMode.sync="operatorMode"
      @toggleOperator="toggleOperator"
    />
  </div>
  <div v-else>
    <Navbar
      :user="user"
      :device="device"
      @logout="logout"
      :operatorMode.sync="operatorMode"
      @toggleOperator="toggleOperator"
    />
  </div>
</template>
<script>
import Sidebar from "./Sidebar/index.vue";
import Navbar from "./Navbar/index.vue";

import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";
export default {
  name: "SidebarMenu",
  components: {
    Sidebar,
    Navbar,
  },
  props: {
    device: {
      type: String,
      required: "desktop",
    },
    operatorMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: { first_name: '', last_name: '', photo: ''}
    };
  },
  methods: {
    toggleOperator() {
      this.$emit("toggleOperator");
    },
    logout() {
      const lista = JSON.parse(localStorage.getItem("loggedAccountsInfo"));

      const { guid } = this.user;
      const indexGuidAtual = lista.map((item) => item.guid).indexOf(guid);
      lista.splice(indexGuidAtual, 1);

      localStorage.setItem("loggedAccountsInfo", JSON.stringify(lista));

      useJwt
        .logout(this.$router.currentRoute.params.prefix)
        .then((response) => {
          localStorage.clear();

          // Reset ability
          this.$ability.update(initialAbility);

          // Delete cookie
          const domain = window.location.href.includes("indsiders.com");
          this.$cookies.remove(
            "userInfo",
            null,
            domain ? "indsiders.com" : null
          );
          // Redirect to login page
          window.location.href = `${this.$myIndLink}login`;
        })
        .catch((error) => {});
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("userData"));
  },
};
</script>
