<template>
  <b-popover
    :custom-class="`navigation_popover ${customPosition}`"
    :triggers="triggers"
    :target="target"
    :placement="placement"
    :offset="offset"
    :show.sync="show"
    boundary="viewport"
  >
  <header v-show="title">{{ title }}</header>
  <div v-for="(item, index) in items">
      <button
        :key="`menu-item-${item.name + index}`"
        @click="handleItemClick(item)"
      >
        <div :class="['icon', item.title.includes('Support') ? 'support-icon' : '']" v-if="item.icon">
          <component :is="item.icon" />
        </div>
        {{ $t(item.title) }}
      </button>
    </div>
  </b-popover>
</template>

<script>
  import HomeIcon from '@core/assets/icons/home-icon.svg';
  import { BPopover } from 'bootstrap-vue';
  export default {
    name: 'PopOver',
    components: { HomeIcon, BPopover },
    props: {
      items: {
        type: Array,
        required: true
      },
      placement: {
        type: String,
        default: 'right'
      },
      title: {
        type: String
      },
      triggers: {
        type: String,
        default: 'focus'
      },
      target: {
        type: String,
        required: true
      },
      offset: {
        type: Number,
        default: 0
      },
      customPosition: {
        type: String,
        default: null
      }
    },
    data: () => ({ show: false }),
    computed: {
      filteredItems() {
        return this.items.reduce((result, item) => {
          const { group, ...rest } = item;
          if (!result[group]) {
            result[group] = [];
          }
          result[group].push(rest);
          return result;
        }, {});
      }
    },
    methods: {
      handleItemClick(item) {
        item.click();
        this.show = false;
      }
    }
  };
</script>

<style lang="scss">
  .navigation_popover {
    .arrow {
      display: none !important;
    }
    .popover-body {
      padding: 0 !important;
      width: inherit !important;
      border: none !important;
    }
    width: 180px !important;

    &.navbar {
      top: -3px !important;
      left: 40px !important;
    }

    &.sidebar {
      top: -20px !important;
    }
  }
</style>

<style lang="scss" scoped>
  .icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: #998F8A;
    }
  }
  .popover {
    display: flex;
    width: 100%;
    padding: 8px 0px;
    border-radius: 6px;
    border: none;
    background: #fff;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);

    header {
      padding: 12px 21px;
      font-size: 14px;
      font-weight: 600;
      color: #4c4541;
    }

    button {
      outline: 0;
      background: transparent;
      border: 0;
      cursor: pointer;

      width: 100%;
      padding: 12px 21px;

      display: flex;
      flex-direction: row;
      gap: 9px;

      font-size: 14px;
      font-weight: 400;
      color: #998f8a;

      user-select: none;

      &:hover {
        background-color: #ffede2;
        color: #974900;
        svg {
          fill: #974900;
        }

        .support-icon {
          svg {
            stroke: #974900;
          }
        }
      }

      &:active {
        background-color: #ffdbc4;
      }
      &.selected {
        background: #974900;
      }
    }
  }
</style>
